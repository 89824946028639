import axios from "axios";
import { gnov } from "flowy-3-core";
import config from "../config";

interface IParams {
  page: number;
  pageSize?: number;
}

interface IRow {
  key: string;
  folio: string;
  municipality: string;
}

interface IColumn {
  title: string;
  dataIndex: string;
  key: string;
  align: string;
}

export interface IPagination {
  page: number;
  total: number;
  pageSize: number;
  lastPage?: number;
}

export interface IIndex {
  pagination: IPagination;
  columns: IColumn[];
  rows: IRow[];
}

function getIndex({ page, pageSize }: IParams): Promise<IIndex | undefined> {
  return new Promise<IIndex | undefined>(async (resolve) => {
    const fid = "641db7a9a39f09001503cccf";
    const url = `${config.apiUrl}/main_api/v1/public/root_gizmo/data/${fid}`;
    try {
      const response = await axios({
        method: "POST",
        url,
        data: {
          page,
          pageSize,
        },
      });

      if (response.status === 200) {
        // console.log('-----------------d: ', response.data);
        const { filledForms } = response.data;

        const total = +response.headers["x-total"];
        const pageFromServer = +response.headers["x-page"];
        const pageSizeFromServer = +response.headers["x-per-page"];

        const rows: IRow[] = filledForms.map((item: any) => {
          const key = gnov(item, "fid");
          const folio = gnov(item, "answers.q2.0.value");
          const municipality = gnov(item, "answers.q1.0.value");

          return { key, folio, municipality };
        });

        const columns: IColumn[] = [
          {
            title: "Folio",
            dataIndex: "folio",
            key: "folio",
            align: "center",
          },
          {
            title: "Municipio",
            dataIndex: "municipality",
            key: "municipality",
            align: "center",
          },
        ];

        const index: IIndex = {
          pagination: {
            page: pageFromServer,
            pageSize: pageSizeFromServer,
            total,
          },
          rows,
          columns,
        };

        // console.log('******index: ', index);
        resolve(index);
      }
    } catch (e) {
      console.error("eeeeeeeeeee: ", e);
    }
  });
}

export default getIndex;
