import { FC, useState, useEffect, useRef } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./TextField.styles";
import { GizmoWrapper } from "../../utils";
import { Button, Select, Space, Spin } from "antd";
import debounce from "lodash/debounce";
import { QrcodeOutlined } from "@ant-design/icons";
import { QrReader } from "react-qr-reader";
import clone from "clone";
import { BehaviorSubject, debounceTime } from "rxjs";

type TextFieldProps = {
  gizmo: Gizmo;
};

interface ICodeScanner {
  show: boolean;
  label: string;
}
const { Option } = Select

const TextField: FC<TextFieldProps> = ({ gizmo }) => {
  const { features, binder, errors, config } = useGizmo({ gizmo });
  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<{ value: string | any }[]>([]);
  const [loading, setLoading] = useState(false);
  const [isAutoComplete, setIsAutoComplete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [codeScanner, setCodeScanner] = useState<ICodeScanner | undefined>(
    binder.getCodeScanner()
  );
  const scannerChange = useRef<BehaviorSubject<any> | undefined>();

  useEffect(() => {
    if (binder) {
      // console.log("***********config: ", config);
      // console.log("cs: ", binder.getCodeScanner());
      setIsAutoComplete(!!config.ops?.search)
      binder.value.subscribe((v: string) => {
        if (config.ops?.search) {
          v.length > 0 && setValue(v);
        } else {
          setValue(v);
        }
      });
      binder.codeScanner.subscribe((cs: ICodeScanner) => {
        setCodeScanner(clone(cs));
      });
      // binder.searchResults.subscribe((searchResults: any)=> {
      //   console.log("*****searchResults", searchResults);
      // });
      gizmo.gizmoSearchFeatures.subscribe((gsf: any) => {
        setLoading(gsf.processing)
        if (!gsf.processing) {
          setOptions(gsf.results)
          setIsOpen(gsf.results.length > 0)
        }
      });

      const initiValue = binder.getValue();
      if (initiValue) {
        setValue(initiValue);
      }
    }

    let subscription: any | undefined;
    if (config.ops?.textField?.codeScannerEnabled) {
      scannerChange.current = new BehaviorSubject<any>("");
      const scannerChange$ = scannerChange.current.asObservable();
      subscription = scannerChange$
        .pipe(debounceTime(500))
        .subscribe(async (value) => {
          if (value) {
            binder.setCodeScannerResult(value);
          }
        });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      // binder?.value.unsubscribe();
      // binder?.codeScanner.unsubscribe();
    };
  }, [binder]);

  const handleChange = (value: string) => {
    // console.log("handleChange", value);
    if (config.ops?.textField?.keyboard === "numeric") {
      const reg = /^-?\d*(\.\d*)?$/;

      if (reg.test(value) || value === "" || value === "-") {
        binder?.setValue(value);
      }
    } else {
      binder?.setValue(value);
    }
  };

  const handlePressEnter = async () => {
    await binder?.sendSpecialKey("enter");
  };

  const handleQrButtonPress = () => {
    binder?.toggleCodeScanner();
  };

  const handleResultQrReader = (result: any) => {
    // console.log("handleResultQrReader", result);
    if (result && result.text) {

      scannerChange.current?.next(result.text);
      // We're going to toggle in order to close the scanner
      binder?.toggleCodeScanner();
      // scannerChange.next(result.text);
    }
  };

  let type = "text";
  if (config.ops?.textField?.password?.isPassword) {
    type = "password";
  }

  /**
   * Function to handle the selection of a search result
   * 
   * @param {string} value value of the selected search result
   */
  const onSelect = async (value: string) => {
    setIsOpen(false)
    // First we ask the gizmo the search results
    const gsf = gizmo.getGizmoSearchFeatures();

    if (gsf && gsf.results && gsf.results.length > 0) {
      // We look for the one that matches the value
      const result = gsf.results.find((r: any) => r.value === value);

      if (result) {
        // And we send it to the gizmo
        await gizmo.selectGizmoSearchResult(result);
      }
    }
  };

  const onSearch = debounce((value: string) => {
    binder?.setValue(value);
  }, 500)

  return (
    <GizmoWrapper features={features} errors={errors}>
      {!features.editable ? (
        <span className="notranslate">{value}</span>
      ) : !isAutoComplete ? (
        <S.TextField
          type={type}
          aria-label={`input-${config.fid}`}
          value={value || ""}
          onChange={(e) => handleChange(e.target.value)}
          onPressEnter={handlePressEnter}
        />
      ) : (
        <>
          <Select
            showSearch
            onSearch={onSearch}
            onChange={onSelect}
            loading={loading}
            showArrow={loading}
            filterOption={false}
            value={value}
            notFoundContent={null}
            onClick={() => setIsOpen(!isOpen)}
            onBlur={() => setIsOpen(false)}
            open={isOpen}
          >
            {options.map(({ value }) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
          {loading && <div style={{ textAlign: 'center' }}><Spin /></div>}
          {codeScanner && features.editable && (
            <>
              <Space>
                <Button icon={<QrcodeOutlined />} onClick={handleQrButtonPress}>
                  {codeScanner.label}
                </Button>
              </Space>
            </>
          )}
        </>
      )}
      {codeScanner?.show && (
        <QrReader
          videoContainerStyle={{ paddingTop: 10 }}
          videoStyle={{
            maxWidth: 500,
            maxHeight: 500,
            position: "relative",
            display: "initial",
          }}
          onResult={handleResultQrReader}
          constraints={{
            facingMode: "environment",
          }}
        />
      )}
    </GizmoWrapper >
  );
};

export default TextField;
