import { useState, useEffect, FC } from "react";
import { Input, Button, Tooltip, Space, Row, Col, Typography } from "antd";
import { SearchOutlined, QrcodeOutlined } from "@ant-design/icons";
import { QrReader } from "react-qr-reader";
import SearchBarController from "./SearchBarController";

const { Text } = Typography;

type SearchBarProps = {
  onResult: (result: any) => void;
};

const SearchBar: FC<SearchBarProps> = ({ onResult }) => {
  const [controller, setController] = useState<SearchBarController | null>(
    null
  );
  const [curp, setCurp] = useState<string>("");
  const [folio, setFolio] = useState<string>("");
  const [curpError, setCurpError] = useState<string | null>(null);
  const [folioError, setFolioError] = useState<string | null>(null);
  const [qrReaderMode, setQrReaderMode] = useState<"curp" | "folio" | null>(
    null
  );
  const [searching, setSearching] = useState<boolean>(false);

  useEffect(() => {
    const controller = new SearchBarController();

    controller.result.subscribe((result) => {
      onResult(result);
    });
    controller.curp.subscribe((curp) => {
      setCurp(curp);
    });
    controller.folio.subscribe((folio) => {
      setFolio(folio);
    });
    controller.errorCurp.subscribe((error) => {
      setCurpError(error);
    });
    controller.errorFolio.subscribe((error) => {
      setFolioError(error);
    });
    controller.searching.subscribe((searching) => {
      setSearching(searching);
    });

    setController(controller);
  }, []);

  const handleCurpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    controller?.setCurp(event.target.value);
  };

  const handleCurpQrClick = () => {
    setQrReaderMode("curp");
  };

  const handleFolioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    controller?.setFolio(event.target.value);
  };

  const handleFolioQrClick = () => {
    setQrReaderMode("folio");
  };

  const handleSearchClick = async () => {
    await controller?.search();
  };

  const handleResultQrReader = (result: any) => {
    if (result?.text) {
      if (qrReaderMode === "curp") {
        controller?.setCurp(result.text);
      } else if (qrReaderMode === "folio") {
        controller?.setFolio(result.text);
      }

      setQrReaderMode(null);
    }
  };

  const handleCancelQrReader = () => {
    setQrReaderMode(null);
  };

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row gutter={8} style={{ width: "100%" }}>
          <Col span={20}>
            <Input
              addonBefore="CURP"
              placeholder="Escribir o Escanear CURP"
              onChange={handleCurpChange}
              value={curp}
            />
            {curpError && <Text type="danger">{curpError}</Text>}
          </Col>
          <Col span={4}>
            <Tooltip title="Escanear QR de CURP">
              <Button
                icon={<QrcodeOutlined />}
                type="primary"
                onClick={handleCurpQrClick}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={8} style={{ width: "100%" }}>
          <Col span={20}>
            <Input
              addonBefore="FOLIO REG."
              placeholder="Escribir o Escanear Folio de Registro"
              onChange={handleFolioChange}
              value={folio}
            />
            {folioError && <Text type="danger">{folioError}</Text>}
          </Col>
          <Col span={4}>
            <Tooltip title="Escanear QR de Folio de Registro">
              <Button
                icon={<QrcodeOutlined />}
                type="primary"
                onClick={handleFolioQrClick}
              />
            </Tooltip>
          </Col>
        </Row>
        {qrReaderMode && (
          <Row gutter={8} style={{ width: "100%" }}>
            <>
              <QrReader
                videoContainerStyle={{ paddingTop: 10 }}
                videoStyle={{
                  maxWidth: 500,
                  maxHeight: 500,
                  position: "relative",
                  display: "initial",
                }}
                onResult={handleResultQrReader}
                constraints={{
                  facingMode: "environment",
                }}
              />
              <Button danger onClick={handleCancelQrReader}>
                Cerrar
              </Button>
            </>
          </Row>
        )}
        <Row gutter={8} style={{ width: "100%" }}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearchClick}
            loading={searching}
          >
            Buscar
          </Button>
        </Row>
        <Row gutter={8} style={{ width: "100%" }}></Row>
      </Space>
    </>
  );
};

export default SearchBar;
