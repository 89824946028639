import { FC } from "react";
import { IError } from "flowy-3-core";
import * as S from "./Errors.styles";
import { Typography } from "antd";

type ErrorsProps = {
  errors: IError[];
};

const { Text } = Typography;

const Errors: FC<ErrorsProps> = ({ errors }) => {
  return (
    <S.Errors>
      {errors.length > 0 &&
        errors.map((error: IError, index: number) => (
          <>
            <Text key={`${index}`} type="danger">
              {error.message}
            </Text>
            <br />
          </>
        ))}
    </S.Errors>
  );
};

export default Errors;
